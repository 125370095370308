import React from 'react'
import navlogo from '../../assets/nav-logo.jpg';
import './cw_privacy_policy.scss';

export const Cw_privacy_policy = () => {
  return (
    <div class="section-home-cwpolicy">
    <nav className="navbar navbar-expand-lg navbar-light ">

   <a className="navbar-brand" href="#">
       <img src={navlogo} alt="Logo" style={{maxHeight: '80px'}} />
   </a>
  
</nav>



<div className='content'>

<center><h1>CODEWENTS Privacy Policy</h1></center>
<p>

At CODEWENTS, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and protect the personal data of users who interact with our eCommerce product. By using our product, you agree to the practices outlined in this policy.

<h5>Information We Collect</h5>
<span>Login Information:</span> When users create an account or log in, we collect information such as email addresses, usernames, and passwords.
<br/><span>Profile Information: </span> Users may provide additional information to personalize their profiles, including names, contact details, and any other information they choose to share.
<br/><span>Real-Time Location Data:</span> For products that include delivery updates, we may collect real-time location information to provide accurate and timely updates regarding the delivery status.
<h5>How We Use Your Information</h5>
We use the information collected from users for the following purposes:
<br/>
<br/><span>Account Management:</span> To create and manage user accounts, including authentication and authorization.
<br/><span>Personalization:</span> To customize user experiences within the product based on profile information.
<br/><span>Customer Support:</span> To provide customer support and address any issues or questions users may have.
<br/><span>Security:</span> To protect against unauthorized access, fraud, and other security risks.
<span>Delivery Updates:</span> For products involving delivery services, we use real-time location data to provide users with accurate delivery tracking and updates.

<h5>Data Sharing and Disclosure</h5>
We do not share, sell, or disclose any personal information collected through our eCommerce product to third parties, except as required by law. All personal data remains confidential and is used solely for the purposes outlined in this Privacy Policy.
<h5>Data Security</h5> 
We take data security seriously and have implemented appropriate technical and organizational measures to safeguard your personal information against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access.

<h5>Data Retention</h5> 
We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected or as required by law. When personal data is no longer needed, we will securely delete or anonymize it.

<h5>Your Rights</h5> 
Users have the following rights concerning their personal information:
<br/>
<br/><span>Access:</span> You have the right to access the personal information we hold about you.
<br/><span>Correction:</span> You have the right to request corrections to any inaccurate or incomplete personal information.
<br/><span>Deletion:</span> You have the right to request the deletion of your personal information, subject to legal and contractual obligations.
<br/><span>Restriction:</span> You have the right to request restrictions on the processing of your personal information under certain circumstances.

<h5>Children's Privacy</h5> 
Our eCommerce product is not intended for use by children under the age of 13. We do not knowingly collect personal information from children. If we become aware that a child has provided us with personal information, we will take steps to delete it.


<h5>Changes to This Privacy Policy</h5> 
We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational reasons. We will notify users of any significant changes by posting the new Privacy Policy on our website and updating the effective date.

<h5>Contact Us</h5> 
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
<br/>
<br/><span>CODEWENTS</span>
<br/><span>Address:</span>2/2, Kandhasami Nagar, Bharathiyar Road, Ganapathy,
Coimbatore - 641006
<br/><span>Phone:</span> 7200493069
<br/><span>Email:</span> support@codewents.com
<br/><span>GSTIN:</span> 33AASFC1749C1ZL

</p>

</div>



</div>
)

}