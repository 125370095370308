import React, { useEffect } from 'react';

const Felgendoktor_mainpage = () => {
    useEffect(() => {
        // Function to check if the app is installed
        const isAppInstalled = () => {
            // Implement logic to check if the app is installed
            // You might need to use navigator.userAgent to determine this
            return false; // Placeholder logic
        };

        // If the app is installed, handle the deep link within the app
        if (!isAppInstalled()) {
            // If the app is not installed, redirect to the Play Store
            redirectToPlayStore();
        }
    }, []);

    const redirectToPlayStore = () => {
        // Redirect to the Play Store
        window.location.href = "https://play.google.com/store/apps/details?id=com.codewents.foodapp";
    };

    return (
        <div>
            <p>Redirecting to playstore</p>
            {/* Add any other content you want to display on the Felgendoktor_mainpage */}
        </div>
    );
};


export default Felgendoktor_mainpage;