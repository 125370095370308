import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import { Home } from './pages/Home';
import { MySpellBeeTrainer_Policy } from './pages/spellbee_trainer/MySpellBeeTrainer_Policy';
import {Cw_privacy_policy} from './pages/codewents/Cw_privacy_policy';
import FelgendoktorMainpage from './pages/felgendoktor/Felgendoktor_mainpage'; // Adjust the import path as needed
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>;

function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/felgendoktorapp/red/:userName" element={<FelgendoktorMainpage />} />
          <Route path="/my-spellbee-trainer" element={< MySpellBeeTrainer_Policy />} />
          <Route path="/codewents-privacy-policy" element={<Cw_privacy_policy/>} />

          {/* Add other routes as needed */}
        </Routes>
      </Router>
    </div>
  );
}


export default App;
