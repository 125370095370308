import '../pages/Home.scss';
import '../index.css';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import navlogo from '../assets/nav-logo.jpg'
import contactlogo from '../assets/contact-logo.png'
import wents from '../assets/wents.png'
import homeimg from '../assets/home.png'
import aboutus from '../assets/about_us.png'
import ettik from '../assets/ettik.png'
import ancitc from '../assets/ancitc.png'
import mayag from '../assets/mayag.png'
import braini from '../assets/braini.png'
import ai from '../assets/ai.png'
import android from '../assets/Android.png'
import desktop from '../assets/desktop.png'
import marketing from '../assets/marketing.png'
import microsoft from '../assets/microsoft.png'
import expertly from '../assets/expertly.png'
import languages from '../assets/languages.png'
import web from '../assets/web.png'
import { faCheck,faHome,faPhone,faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck} from '@fortawesome/free-regular-svg-icons'
import { faFacebook,faInstagram,faLinkedin,faXTwitter,faYoutube} from '@fortawesome/free-brands-svg-icons'
export const Home = () => {
  return (
    <div class="section-home">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#">
                <img src={navlogo} alt="Logo" style={{maxHeight: '50px'}} />
            </a>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                    <a className="nav-link" href="#">Home</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">About</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Services</a>
                </li>
                {/* Add more links as needed */}
                </ul>
                <form className="form-inline my-2 my-lg-0">
                <button className="btn  my-2 my-sm-0" type="submit">contact us</button>
                </form>
            </div>
        </nav>
        <section className="home-section">
              
                    <div className="row">
                    <div className="col-md-6 content">
                        <h2>We tech your tomorrow. </h2>
                        <p>Transform your business with our cutting-edge technology. The future is within reach, and we'll help you get there.</p>
                        <button className="btn btn-outline-success my-2 my-sm-0 btn-content" type="button">Get quote</button>
                    </div>
                    <div className="col-md-6 img-container img-fluid">
                        <img src={homeimg} alt="Image" />
                    </div>
                    </div>
                
        </section>
        <section className="customers-section">
            <h6 className="section-title">Trusted by</h6>
            <div className="image-container">
                <img src={ettik} alt="Image 1" />
                <img src={braini} alt="Image 2" />
                <img src={mayag} class= "img3" alt="Image 3" />
                <img src={ancitc}  class="img4" alt="Image 4" />
            </div>
        </section>
        <section className="about-section">

            <div className="row">
              <div className="col-md-6 content">
                  <h2>We build the <span>best-in-class</span> advanced software products.</h2>
                  <p>Our customer-centric philosophy has inspired our team to go above and beyond for the customers, ensuring that they always come first. With codewents, you can be sure that you're getting the best of the best.</p>
                  <div className="line"></div>
                  <div className="about_points">
                        <div className='point1 point'>
                            <FontAwesomeIcon className="icon-1"icon={faCircleCheck} />
                            <h4>UI</h4>
                            <p> We craft intuitive, user-centric UI design.</p>
                        </div>
                        <div className='point2 point'>
                            <FontAwesomeIcon className="icon-i"icon={faCircleCheck} />
                            <h4>UX</h4>
                            <p> We prioritize user-driven UX design.</p>
                        </div>    
                  </div>
              </div>
              <div className="col-md-6 img-container img-fluid">
                  <img src={aboutus} alt="Image" />
              </div>
              </div>
          
         </section>
         <section className='services-section'>
            <div className="container">
                    <div className="top-row row">
                        <h2>Our Services</h2>
                        <div className="card">
                        <img src={web} alt="img"/>
                        <h3>Web development</h3>
                        <p>We are your comprehensive web development solution, proficient in crafting websites across all technologies and frameworks, catering to diverse needs and requirements.</p>
                        </div>
                        <div className="card">
                        <img src={marketing} alt="img"/>
                        <h3>Digital Marketing</h3>
                        <p>We excel in leveraging cutting-edge digital marketing strategies to enhance brand visibility and drive impactful online engagement.</p>
                        </div>
                        <div className="card">
                        <img src={android} alt="img"/>
                        <h3>Mobile app development</h3>
                        <p>We specialize in developing Android and iOS applications using both native and cross-platform frameworks.</p>
                        </div>
                    </div>
                    <div className="bottom-row row">
                        <div className="card">
                        <img src={desktop} alt="img"/>
                        <h3>Desktop application development</h3>
                        <p>We offer full-stack desktop application development expertise, utilizing a wide range of  technologies to deliver tailored solutions for diverse needs and preferences.</p>
                        </div>
                        <div className="card">
                        <img src={microsoft} alt="img"/>    
                        <h3>Microsoft Power platform development</h3>
                        <p>Low-code tools for organizations to innovate and transform their business operations.
</p>
                        </div>
                        <div className="card">
                        <img src={ai} alt="img"/>
                        <h3>Artificial intelligence / Machine Learning</h3>
                        <p>Artificial intelligence and machine learning applications leverage data-driven algorithms to automate tasks, make predictions and decision-making across various domains.</p>
                        </div>
                    </div>
            </div>

         </section>
         <section className="expert-section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 content">
                        <h2>We keep our customers always ahead of their competitors.</h2>
                        <p>Unlike finding gaps in the age old products, our strategy is to forecast the technology trends and build the best-in-class advanced products.</p>
                    </div>
                    <div className="col-md-6 img-container">
                        <img src={expertly} alt="Image" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
        <section className="languages-section">

            <div className="row">
            <div className="col-md-6 content">
                <h2>Crafting dynamic solutions,always attuned to the  <span>forefront
of technology trends.</span></h2>
                <p>We continuously adapt to emerging tech trends, enabling us to deliver 
top-tier services that leverage the most innovative tools and platforms 
available.</p>
                
            </div>
            <div className="col-md-6 img-container img-fluid">
                <img src={languages} alt="Image" />
            </div>
            </div>

        </section>
        <section className='pricing-section'>
                <div className="container ">
                    <div className="row ">
                        <h2>Choose the website plan that best suits you</h2>
                        <div className="card-container">
                            <div className="card">
                                <div className="card-body">
                                  

                                        <h3 className="card-title">Basic</h3>
                                        <p className='plan-description'>Best to get started</p>
                                        <h4 className="card-subtitle mb-2 text-muted">Rs. 9,999</h4>
                                  
                                    
                                    <div className="line"></div>
                                    <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <FontAwesomeIcon icon={faCheck} /> 5 Pages
                                        </li>
                                        <li className="list-group-item">
                                        <FontAwesomeIcon icon={faCheck} /> Static Website (Premium Design)
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon icon={faCheck} /> 1 Year Free Domain Name (.com, .in, .org)
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon icon={faCheck} /> 1 Year Free Hosting
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon icon={faCheck} /> 1 Year Free SSL Certificate
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon icon={faCheck} /> 100% Responsive Website
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon icon={faCheck} /> SEO Friendly Website
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon icon={faCheck} /> Social Media Integration
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon icon={faCheck} /> Inquiry Form
                                        </li>
                                    </ul>
                                    <a href="#" className="btn ">Select Plan</a>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Economy</h3>
                                    <p className='plan-description'>Built for scaling business</p>
                                    <h4 className="card-subtitle mb-2 text-muted">Rs. 16,999</h4>
                                    <div className="line"></div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> 8 Pages
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Dynamic Website (Premium Design)
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> 1 Year Free Domain Name (.com, .in, .org)
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> 1 Year Free Hosting
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> 1 Year Free SSL Certificate
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> 100% Responsive Website
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> SEO Friendly Website
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Social Media Integration
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Call Button Integration
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> WhatsApp Button Integration
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Inquiry Form
                                        </li>
                                    </ul>
                                    <a href="#" className="btn ">Select Plan</a>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Advanced</h3>
                                    <p className='plan-description'>Fully tailored for your enterprise</p>
                                    <h4 className="card-subtitle mb-2 text-muted">Rs. 29,999</h4>
                                    <div className="line"></div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> 30 Pages Website
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Dynamic Website (Premium Design)
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> 1 Year Free Domain Name (.com, .in, .org)
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> 1 Year Free Hosting
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> 1 Year Free SSL Certificate
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> 100% Responsive Website
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> SEO Friendly Website
                                        </li>

                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Social Media Integration
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Call Button Integration
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> WhatsApp Button Integration
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Inquiry Form
                                        </li>
                                    
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Ecommerce Features
                                        </li>
                                        
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Payment Gateway Integration
                                        </li>
                                        <li className="list-group-item">
                                            <FontAwesomeIcon className="icon-1" icon={faCheck} /> Google Analytics Integration
                                        </li>
                                    </ul>
                                    <a href="#" className="btn ">Select Plan</a>
                                </div>
                            </div>
                        </div>
                       
                        {/* Add more col-md-4 here for other pricing plans */}
                    </div>
                </div>
        </section>
        <section className='map-section'>
          <div className='maps'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3915.873563221164!2d76.97055987355607!3d11.048105154126754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859492b739aa7%3A0x4e259e746a7c022e!2sBrainix%20innovation%20center!5e0!3m2!1sen!2sin!4v1711185044095!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

        </section>
        <section className='footer-section'>
            <div className='contact-details'>
                <div className='contact-company'>
                    <a className="navbar-brand" href="#">
                        <img src={contactlogo} alt="Logo" style={{maxHeight: '50px'}} /> 
                    </a>
                    <div className='social-media'>
                            <FontAwesomeIcon className="icon-1"icon={faFacebook} />
                            <FontAwesomeIcon className="icon-1"icon={faInstagram} />
                            <FontAwesomeIcon className="icon-1"icon={faYoutube} />
                            <FontAwesomeIcon className="icon-1"icon={faXTwitter} />
                            <FontAwesomeIcon className="icon-1"icon={faLinkedin} />
                    </div>
                    <img src={wents} alt="Logo" style={{maxWidth: '200px'}} /> 
                </div>
                <div className='contact-form-address'>
                    <div className='contact-form'>
                        <form action="" method="POST">
                               
                                <input type="text" id="name" name="name"  placeholder="name" required/><br></br>
                                
                            
                                <input type="email" id="email" name="email"placeholder="email" required/><br></br>
                                
                              
                                <textarea id="message" name="message" placeholder="write your message here"rows="4" required></textarea><br></br>
                                
                                <input type="submit" class="submit-btn" value="Submit"/>
                        </form>
                    </div>
                    <div className='contact-address'>
                        <h4>Contact us</h4>
                        <h5><FontAwesomeIcon className="icon-i"icon={faPhone} />Phone</h5>
                        <p>+91 7200493069</p>
                        <h5><FontAwesomeIcon className="icon-i"icon={faEnvelope} />Email</h5>
                        <p>support@codewents.com</p>
                        <h5><FontAwesomeIcon className="icon-i"icon={faHome} />Address</h5>
                        <p>Brainix innovation center, Manikarampalayam, Ganapathy, Coimbatore, Tamil Nadu 641006</p>
                    </div>
                </div>
            </div>
            <div className='line'></div>
            <div className='links-copyrights'>
                <div className='links'>
                     <p><a className="nav-link" href="#">Home</a> | <a className="nav-link" href="#">About us</a> | <a className="nav-link" href="#">Services</a> | <a className="nav-link" href="#">Pricing</a> | <Link className="nav-link"onClick={() => {window.scrollTo(0,0)}} to="/codewents-privacy-policy">Privacy Policy</Link></p>   
                </div>
                <div className='copyrights'>
                    <p >©2024, <span>CODEWENTS</span>. All rights Reserved </p>
                </div>
            </div>
        </section>
    </div>
  )
}
