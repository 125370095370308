import './MySpellBeeTrainer_Policy.scss';
import navlogo from '../../assets/nav-logo.jpg';


export const MySpellBeeTrainer_Policy = () => {
  return (

    <div class="section-home-spellbee">
             <nav className="navbar navbar-expand-lg navbar-light ">

            <a className="navbar-brand" href="#">
                <img src={navlogo} alt="Logo" style={{maxHeight: '80px'}} />
            </a>
           
        </nav>



        <div className='content'>

        <center><h1>My Spellbee Trainer</h1></center>
 <p>
  <center><h4>Privacy Policy</h4></center>
  CODEWENTS built the My Spellbee trainer app. This service is provided by CODEWENTS at no cost and is intended for use as is.
  
  This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if
   anyone decided to use our app.
  
  If you choose to use our game app, then you agree to the collection and use of information in relation to this policy. The Personal Information 
  that we collect is used for profile maintenance. We will not use or share your information with anyone as described in this Privacy Policy.
  
  The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at My Spellbee trainer 
  unless otherwise defined in this Privacy Policy.
  
  Information Collection and Use
  
  For a better experience, while registering with our app, we may require you to provide us with certain personally identifiable
   information for user profile maintenance. The information that we request will be retained by us and used as described in this privacy
    policy. The app does not use any third-party services that may collect information used to identify you.
  
<h5>Security</h5>
  We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
  
<h5>Children’s Privacy</h5>
  We are collecting personal information for user profile maintenance. Parents should register for your children and then make them play our game app.
   It is not advisable to make children registering our game app with your personal details. 
  
  
  
  
<h5>Changes to This Privacy Policy</h5>
  We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
  This policy is effective as of<span> 2024-05-07.</span>
  
 <h5>Contact Us</h5> 
  If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <span>support@codewents.com.</span>
  </p>

        </div>



    </div>
  )

}
